import React from 'react'
import { categories, categoriesFood } from "../../data";
import { Container, Row } from 'react-bootstrap'
import CategoryItem from '../categoryItem/CategoryItem'
import './categories.css';

const Categories = () => {
    return (
        <>
        <h3 className="sub-headers">Ons Assortiment</h3>
        <Container>
        <Row xs={1} md={3} className="g-4">
            {categories.map((item) => (
                <CategoryItem item={item} key={item.id} />
            ))}
        </Row>
        <Row xs={1} md={2} className="g-4 row-cat-food">
            {categoriesFood.map((item) => (
                <CategoryItem item={item} key={item.id} />
            ))}
        </Row>
        </Container>
        </>
    )
}

export default Categories