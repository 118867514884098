import React from 'react'
import { Carousel } from 'react-bootstrap'
import './slider.css';
import Baklava from '../../img/baklava-slider.jpg'
import Bonbons from '../../img/bonbons-slider.jpg'
import Ijs from '../../img/ijs-slider.jpg'
import Koffie from '../../img/coffee-slider.jpg'
import Cake from '../../img/cake-slider.jpg'
import Donuts from '../../img/donuts-slider.jpg'

const Slider = () => {
    return (
        <Carousel>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={Koffie}
            alt="Koffie"
            />
            <Carousel.Caption>
            <h3>Koffie</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={Ijs}
            alt="Ijs"
            />

            <Carousel.Caption>
            <h3>Ijs</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={Baklava}
            alt="Baklava"
            />
            <Carousel.Caption>
            <h3>Zoetigheid</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={Bonbons}
            alt="Bonbons"
            />

            <Carousel.Caption>
            <h3>Bonbons</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={Cake}
            alt="Cake"
            />

            <Carousel.Caption>
            <h3>Cake</h3>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={Donuts}
            alt="Donuts"
            />

            <Carousel.Caption>
            <h3>Donuts</h3>
            </Carousel.Caption>
        </Carousel.Item>
        </Carousel>
    )
}

export default Slider
