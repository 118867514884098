import React, {useEffect} from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Badge, Breadcrumb, Container, ListGroup, Row } from 'react-bootstrap'
import './productPage.css'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { ontbijtProductItems } from "../data";
import { Link } from 'react-router-dom'
import ProductFood from '../components/productItemFood/ProductFood'


const OntbijtPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Ontbijt</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
        <Container>
            <h3 className="sub-headers">Ontbijt</h3>
            <p className="over-ons-p"><Badge bg="info">Kinder: &euro;3,00</Badge><Badge bg="info">Standaard: &euro;4,50</Badge><Badge bg="info">Luxe: &euro;7,00</Badge><Badge bg="info">SuperLuxe: &euro;9,50</Badge></p>
        </Container>
        <ScrollButton/>
            <Container className='container-products'>
            <Row xs={1} md={2} className="g-4">
                {ontbijtProductItems.map((item) => (
                    <ProductFood item={item} key={item.id} />
                ))}
            </Row>
            </Container>
            <Container>
                <h3 className="sub-headers">Stel uw eigen ontbijt samen!!</h3>
                <p className="over-ons-p"><Badge bg="info">7 Menu items: &euro;3,00</Badge><Badge bg="info">14 Menu items: &euro;4,50</Badge><Badge bg="info">16 Menu items: &euro;7,00</Badge><Badge bg="info">19 Menu items: &euro;9,50</Badge></p>
                <Row xs={1} md={2} className="g-4">
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Roomboter croissant</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Stuk brood</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Simit</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Sneetje brood</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Lebanon brood</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Pogaça</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Koffie</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Thee</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Melk</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Chocolademelk</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Limonade</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Jam</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Nutella</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Hagelslag</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tahin</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Pekmez</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Humus</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Boter</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Honing</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Koekjes</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Turks fruit</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Appel</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Sinaasappel</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Mix fruit</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Kwark (fruit yoghurt )</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Normaal yoghurt </ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Olijven</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Komkommer</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tomaat</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Kikkererwten</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Gekookte scharleitje</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Patat</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Cornflakes</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Kaas</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Feta Kaas</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Salami</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Scrambled ei met worst*</div>
                            Alleen bij keuze 4, of €2,50 extra
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
            </Container>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default OntbijtPage
