import React from 'react';
import { Container } from 'react-bootstrap';
import './overOns.css';

const OverOns = () => {
    return (
        <>
        <Container>
        <h3 className="sub-headers">Over Ons</h3>
        <h4 className="sub-headers">Heerlijke broodjes op authentieke wijze gemaakt</h4>
        <p className="over-ons-p vet">Bij La Fiore bent u op het juiste adres voor de lekkerste hartige en zoete broodjes. Een uitgebreide selectie van de beste authentieke broodjes en zoetwaren zoals gebak en patisserie worden vers voor u bereid. Wij bieden een variatie aan authentieke Arabische specialiteiten en geliefde Europese lekkernijen.
Ook voor take-away!</p>
<p className="over-ons-p">Alle ingrediënten voor een heerlijk lunchmoment vind je bij La Fiore, of laat je verassen door een van onze prachtige ijscoupes, samengesteld uit onze selectie aan verrukkelijk Italiaans ijs.</p>
<p className="over-ons-p">Kom vooral langs en laat je adviseren door ons enthousiaste personeel. Kies ervoor om in ons sfeervolle restaurant plaats te nemen of zoek een plekje in de zon op ons terras.</p>
<p className="over-ons-p vet">Het is ook mogelijk om al onze gerechten af te halen om hier thuis van te genieten of om zomaar iemand te verassen.</p>
        
        </Container>
        </>
    )
}

export default OverOns