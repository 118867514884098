import React, {useEffect} from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Breadcrumb, Container, ListGroup, Row } from 'react-bootstrap'
import './productPage.css'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { Link } from 'react-router-dom'


const LunchPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Lunch</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
        <ScrollButton/>
            <Container>
                <h3 className="sub-headers">Lunch</h3>
                <Row xs={1} md={2} className="g-4">
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Tosti kaas &euro;2,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tosti worst &euro;2,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tosti salami &euro;2,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tosti doner rund &euro;2,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tosti doner kip &euro;2,50</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Panini mozzarella pesto &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Panini kip &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Panini doner &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Panini tonijn &euro;3,00</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Broodje gezond &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje salami &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje worst &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje gehakt &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje kip filet &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje tonijn &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje zalm &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje witte kaas &euro;3,00</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Broodje meneme &euro;3,00</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Borek gehakt &euro;2,75</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Borek witte kaas &euro;2,75</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Borek spinazie &euro;2,75</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Borek aardappel &euro;2,75</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Borek doner &euro;2,75</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Su borek &euro;2,75</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Tortilla doner rund &euro;3,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tortilla doner kip &euro;3,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tortilla gehakt &euro;3,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Tortilla Quattro xheese &euro;3,50</ListGroup.Item>
                    </ListGroup>
                    <ListGroup as="ol">
                        <ListGroup.Item action variant="light" as="li">Turkse pizza &euro;2,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Simit &euro;2,50</ListGroup.Item>
                        <ListGroup.Item action variant="light" as="li">Croissant &euro;2,50</ListGroup.Item>
                    </ListGroup>
                </Row>
            </Container>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default LunchPage
