import React from 'react'
import Categories from '../components/categories/Categories'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Slider from '../components/slider/Slider'
import Footer from '../components/footer/Footer'
import { Container } from 'react-bootstrap'

const Home = () => {
    return (
        <>
        <Navbar/>
        <Slider/>
        <Container>
        <h3 className="sub-headers">Over Ons</h3>
        <p className="over-ons-p">Bij La Fiore bent u op het juiste adres voor de lekkerste hartige en zoete broodjes. Een uitgebreide selectie van de beste authentieke broodjes en zoetwaren zoals gebak en patisserie worden vers voor u bereid. Wij bieden een variatie aan authentieke Arabische specialiteiten en geliefde Europese lekkernijen.
Ook voor take-away!</p>
        </Container>
        <Categories/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default Home
