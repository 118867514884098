import React from 'react'
import Categories from '../components/categories/Categories'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'

const ProductenPage = () => {
    return (
        <>
        <Navbar/>
        <Categories/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default ProductenPage
