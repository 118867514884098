import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './categoryItem.css';

const CategoryItem = ({ item }) => {
    return (
        <Col>
        <Link className='link-card-item' to={item.link}>
            <Card className="cards-row">
                <Card.Img variant="top" src={item.img} />
                <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                </Card.Body>
            </Card>
        </Link>
        </Col>
    )
}

export default CategoryItem
