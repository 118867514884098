import React, { useEffect } from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Breadcrumb, Container } from 'react-bootstrap'
import './productPage.css'
import Products from '../components/products/Products'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { feesttaartenProductItems } from "../data"
import { Link } from 'react-router-dom'


const FeesttaartPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Feesttaarten</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
        <Container>
            <h3 className="sub-headers">Feesttaarten</h3>
        </Container>
        <ScrollButton/>
        <Products productItems={feesttaartenProductItems}/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default FeesttaartPage
