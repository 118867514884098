import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OverOnsPage from "./pages/OverOnsPage";
import ProductenPage from "./pages/ProductenPage";
import IjsPage from "./pages/IjsPage";
import ContactPage from "./pages/ContactPage";
import TaartenPage from "./pages/TaartenPage";
import KoekjesPage from "./pages/KoekjesPage";
import BonbonsPage from "./pages/BonbonsPage";
import ZoetigheidPage from "./pages/ZoetigheidPage";
import TurksFruitPage from "./pages/TurksFruitPage";
import BroodjesPage from "./pages/BroodjesPage";
import DonutsPage from "./pages/DonutsPage";
import OntbijtPage from "./pages/OntbijtPage";
import LunchPage from "./pages/LunchPage";
import FotoGalerijPage from "./pages/FotoGalerijPage";
import WafelsPage from "./pages/WafelsPage";
import DrankenPage from "./pages/DrankenPage";
import FeesttaartPage from "./pages/FeesttaartPage";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/overOns" element={<OverOnsPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/fotogalerij" element={<FotoGalerijPage/>} />
          <Route path="/onsAssortiment" element={<ProductenPage/>} />
          <Route path="/onsAssortiment/ijs" element={<IjsPage/>} />
          <Route path="/onsAssortiment/taarten" element={<TaartenPage/>} />
          <Route path="/onsAssortiment/koekjes" element={<KoekjesPage/>} />
          <Route path="/onsAssortiment/bonbons" element={<BonbonsPage/>} />
          <Route path="/onsAssortiment/zoetigheid" element={<ZoetigheidPage/>} />
          <Route path="/onsAssortiment/turksfruit" element={<TurksFruitPage/>} />
          <Route path="/onsAssortiment/broodjes" element={<BroodjesPage/>} />
          <Route path="/onsAssortiment/donuts" element={<DonutsPage/>} />
          <Route path="/onsAssortiment/ontbijt" element={<OntbijtPage/>} />
          <Route path="/onsAssortiment/lunch" element={<LunchPage/>} />
          <Route path="/onsAssortiment/wafels" element={<WafelsPage/>} />
          <Route path="/onsAssortiment/dranken" element={<DrankenPage/>} />
          <Route path="/onsAssortiment/feesttaarten" element={<FeesttaartPage/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;