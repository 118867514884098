import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './footer.css'

const Footer = () => {
    return (
        <div className="footer">
            <Container>
            <Row xs={1} md={2}>
                <Col className="footer-col">
                    <span>
                        &copy; Lafiore 2021
                    </span>
                </Col>
                <Col className="footer-col sitemap">
                    <span>
                        Sitemap
                    </span>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Footer
