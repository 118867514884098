import Bonbons from './img/bonbons-category.jpg'
import TurksFruit from './img/turksfruit-category.jpg'
import Taarten from './img/taarten-category.jpg'
import Ijs from './img/ijs-category.jpg'
import Baklava from './img/baklava-category.jpg'
import Koffie from './img/koffie-category.jpg'
import Wafel from './img/wafels-category.jpg'
import Broodje from './img/broodjes-category.jpg'
import Macaron from './img/macaron-category.jpg'
import Donuts from './img/donuts-category.jpg'
import Feesttaart from './img/feesttaart-category.jpg'

import Lunch from './img/lunch-category.jpg'
import Ontbijt from './img/ontbijt-category.jpg'

import Kinder from './img/ontbijt/Kinder.jpg'
import Luxe from './img/ontbijt/Luxe.jpg'
import Standaart from './img/ontbijt/Standaart.jpg'
import SuperLuxe from './img/ontbijt/SuperLuxe.jpg'

import Aardbei from './img/ijs/aardbei.jpg'
import Banaan from './img/ijs/banaan.jpg'
import Bloedsinaasappel from './img/ijs/bloedsinaasappel.jpg'
import Blueberry from './img/ijs/blueberry.jpg'
import Kitkat from './img/ijs/kitkat.jpg'
import Bubblegum from './img/ijs/bubblegum.jpg'
import Caramel from './img/ijs/caramel.jpg'
import Chocolade from './img/ijs/chocolade.jpg'
import ChocoladeWit from './img/ijs/chocolade-wit.jpg'
import Citroen from './img/ijs/citroen.jpg'
import Cocos from './img/ijs/cocos.jpg'
import Cookies from './img/ijs/cookies.jpg'
import Flamingo from './img/ijs/flamingo.jpg'
import Framboos from './img/ijs/framboos.jpg'
import Hazelnoot from './img/ijs/hazelnoot.jpg'
import KinderBueno from './img/ijs/kinder-bueno.jpg'
import Mango from './img/ijs/mango.jpg'
import Meloen from './img/ijs/meloen.jpg'
import Mokka from './img/ijs/mokka.jpg'
import Oreo from './img/ijs/oreo.jpg'
import Pistache from './img/ijs/pistache.jpg'
import Smurf from './img/ijs/smurf.jpg'
import Snickers from './img/ijs/snickers.jpg'
import Stracciatella from './img/ijs/stracciatella.jpg'
import Stroopwafel from './img/ijs/stroopwafel.jpg'
import Suikerspin from './img/ijs/suikerspin.png'
import Vanille from './img/ijs/vanille.jpg'
import Watermeloen from './img/ijs/watermeloen.jpg'
import CherryChoco from './img/ijs/cherry-choco.jpg'
import FramboosAardbei from './img/ijs/framboos-aardbei.jpg'
import OreoCookies from './img/ijs/oreo-cookies.jpg'
import HazelnootPraline from './img/ijs/hazelnoot-praline.jpg'
import YoghurtKers from './img/ijs/yoghurt-kers.jpg'

import TripleRedVelvet from './img/taart/Triple-red-velvet.jpg'
import Rainbow from './img/taart/Rainbow.jpg'
import AardbeienBavarois from './img/taart/Aardbeien-bavarois.jpg'
import Amandel from './img/taart/Amandel.jpg'
import Brownie from './img/taart/Brownie.jpg'
import CaramelCrush from './img/taart/Caramel-crush.jpg'
import CarrotCake from './img/taart/Carrot-cake.jpg'
import CheesecakeFrambozen from './img/taart/Cheesecake-frambozen.jpg'
import Cheesecake from './img/taart/Cheesecake.jpg'
import CitroenBavarois from './img/taart/Citroen-bavarois.jpg'
import HazelnootBavarrois from './img/taart/Hazelnoot-bavarrois.jpg'
import LemonPie from './img/taart/Lemon-pie.jpg'
import MokkaNougat from './img/taart/Mokka-nougat.jpg'
import NakedFramboos from './img/taart/Naked-framboos.jpg'
import NakedSinaasappel from './img/taart/Naked-sinaasappel.jpg'
import OreoTaart from './img/taart/Oreo.jpg'
import RedVelvet from './img/taart/Red-velvet.jpg'
import SinaasappelBavarrois from './img/taart/Sinaasappel-bavarrois.jpg'
import StracciatellaTaart from './img/taart/Stracciatella.jpg'
import StroopwafelTaart from './img/taart/Stroopwafel.jpg'
import TropicalBavarois from './img/taart/Tropical-bavarois.jpg'
import ZeeuwseBabbelaar from './img/taart/Zeeuwse-babbelaar.jpg'
import AppeltaartBetuwse from './img/taart/AppeltaartBetuwse.jpg'
import AppeltaartGrootmoeders from './img/taart/AppeltaartGrootmoeders.jpg'
import AardbeienKneiters from './img/taart/AardbeienKneiters.jpg'
import BanaanSoezen from './img/taart/BanaanSoezen.jpg'
import CappucinoCreme from './img/taart/CappucinoCreme.jpg'
import ChocoladeCremeGebak from './img/taart/ChocoladeCremeGebak.jpg'
import CremeAuBeurreGebak from './img/taart/cremeAuBeurreGebak.jpg'
import Hazelinos from './img/taart/Hazelinos.jpg'
import MokkaCreme from './img/taart/MokkaCreme.jpg'
import Moorkoppen from './img/taart/Moorkoppen.jpg'
import CheesecakeTaart from './img/taart/CheesecakeTaart.jpg'
import ChocoladeTaart from './img/taart/ChocoladeTaart.jpg'
import CitroenMerengue from './img/taart/CitroenMerengue.jpg'
import HazelnootTaartHotkamp from './img/taart/HazelnootTaartHotkamp.jpg'
import VeganAppelkruimeltaart from './img/taart/VeganAppelkruimeltaart.jpg'
import VeganCarrotTaart from './img/taart/VeganCarrotTaart.jpg'
import BananaBreak from './img/taart/BananaBreak.jpg'
import BrownieBastard from './img/taart/BrownieBastard.jpg'
import CarrotCaramba from './img/taart/CarrotCaramba.jpg'
import LemonLicious from './img/taart/LemonLicious.jpg'

import AardbeiMacaron from './img/koekjes/AardbeiMacaron.jpg'
import BlueberryKoekjes from './img/koekjes/BlueberryKoekjes.jpg'
import CaramelAnanasRoomboterkoekjes from './img/koekjes/CaramelAnanasRoomboterkoekjes.jpg'
import caramelMacaron from './img/koekjes/caramelMacaron.jpg'
import CassisMacaron from './img/koekjes/CassisMacaron.jpg'
import ChocoladeMacaron from './img/koekjes/ChocoladeMacaron.jpg'
import ChocoladeRoomboterkoekjes from './img/koekjes/ChocoladeRoomboterkoekjes.jpg'
import CitroenMacaron from './img/koekjes/CitroenMacaron.jpg'
import HazelnootMacaron from './img/koekjes/HazelnootMacaron.jpg'
import HazelnootRoomboterkoekjes from './img/koekjes/HazelnootRoomboterkoekjes.jpg'
import MadagaskarMacaron from './img/koekjes/MadagaskarMacaron.jpg'
import MangoCaramelRoomboterkoekjes from './img/koekjes/MangoCaramelRoomboterkoekjes.jpg'
import PassievruchtMacaron from './img/koekjes/PassievruchtMacaron.jpg'
import PistacheMacaron from './img/koekjes/PistacheMacaron.jpg'
import Stroopwafeltjes from './img/koekjes/Stroopwafeltjes.jpg'
import WitteChocoladeRoomboterkoekjes from './img/koekjes/WitteChocoladeRoomboterkoekjes.jpg'

import AardbeiBonbon from './img/bonbons/Aardbei.jpg'
import Abrinout from './img/bonbons/Abrinout.jpg'
import Amarena from './img/bonbons/Amarena.jpg'
import AppelKaneel from './img/bonbons/AppelKaneel.jpg'
import BanaanRoyaal from './img/bonbons/BanaanRoyaal.jpg'
import Barry from './img/bonbons/Barry.jpg'
import BonbonXLMelkRoomboterCaramel from './img/bonbons/BonbonXLMelkRoomboterCaramel.jpg'
import BonbonXLPuurKrokanteHazelnootPraline from './img/bonbons/BonbonXLPuurKrokanteHazelnootPraline.jpg'
import BonbonXLWitMandarijnYoghurt from './img/bonbons/BonbonXLWitMandarijnYoghurt.jpg'
import Boterkoek from './img/bonbons/Boterkoek.jpg'
import Bouches from './img/bonbons/Bouches.jpg'
import Cafe from './img/bonbons/Cafe.jpg'
import CoconutDream from './img/bonbons/Coconut Dream.jpg'
import DropCaramelZoethout from './img/bonbons/Drop Caramel Zoethout.jpg'
import DuoNoix from './img/bonbons/DuoNoix.jpg'
import FleurDeSel from './img/bonbons/FleurDeSel.jpg'
import Florentine from './img/bonbons/Florentine.jpg'
import FramboosMint from './img/bonbons/FramboosMint.jpg'
import Gember from './img/bonbons/Gember.jpg'
import GreenTea from './img/bonbons/GreenTea.jpg'
import KennsBrownie from './img/bonbons/KennsBrownie.jpg'
import Krokanta from './img/bonbons/Krokanta.jpg'
import LaBorde from './img/bonbons/La Borde.jpg'
import Mandarino from './img/bonbons/Mandarino.jpg'
import MangoCheesecake from './img/bonbons/MangoCheesecake.jpg'
import MarulaMacchiato from './img/bonbons/MarulaMacchiato.jpg'
import Mokha from './img/bonbons/Mokha.jpg'
import Nutella from './img/bonbons/Nutella.jpg'
import OpenTrioGanache from './img/bonbons/OpenTrioGanache.jpg'
import Pendule from './img/bonbons/Pendule.jpg'
import Picasso from './img/bonbons/Picasso.jpg'
import PindaCrunch from './img/bonbons/PindaCrunch.jpg'
import PistacheBonbon from './img/bonbons/Pistache.jpg'
import Poire from './img/bonbons/Poire.jpg'
import PomeloRosa from './img/bonbons/PomeloRosa.jpg'
import Rachel from './img/bonbons/Rachel.jpg'
import Sandoorn from './img/bonbons/Sandoorn.jpg'
import StroopwafelBonbon from './img/bonbons/Stroopwafel.jpg'
import TonkaKoffie from './img/bonbons/TonkaKoffie.jpg'
import Truffel from './img/bonbons/Truffel.jpg'
import Walnos from './img/bonbons/Walnos.jpg'
import XtraPuur from './img/bonbons/XtraPuur.jpg'
import Zacamar from './img/bonbons/Zacamar.jpg'

import Z1 from './img/zoetigheid/Z1.jpg'
import Z2 from './img/zoetigheid/Z2.jpg'
import Z3 from './img/zoetigheid/Z3.jpg'
import Z4 from './img/zoetigheid/Z4.jpg'
import Z5 from './img/zoetigheid/Z5.jpg'
import Z7 from './img/zoetigheid/Z7.jpg'
import Z8 from './img/zoetigheid/Z8.jpg'
import Z9 from './img/zoetigheid/Z9.jpg'
import Z10 from './img/zoetigheid/Z10.jpg'
import Z11 from './img/zoetigheid/Z11.jpg'
import Z12 from './img/zoetigheid/Z12.jpg'
import Z13 from './img/zoetigheid/Z13.jpg'
import Z14 from './img/zoetigheid/Z14.jpg'
import Z15 from './img/zoetigheid/Z15.jpg'
import Z16 from './img/zoetigheid/Z16.jpg'
import BasmaKadayif from './img/zoetigheid/BasmaKadayif.jpg'
import BurmaKadayif from './img/zoetigheid/BurmaKadayif.jpg'
import Vezir from './img/zoetigheid/Vezir.jpg'
import CevizliBaklava from './img/zoetigheid/CevizliBaklava.jpg'
import CevizliDolma from './img/zoetigheid/CevizliDolma.jpg'
import CevizliDurum from './img/zoetigheid/CevizliDurum.jpg'
import CikolataliBaklava from './img/zoetigheid/CikolataliBaklava.jpg'
import Dilber from './img/zoetigheid/Dilber.jpg'
import Durum from './img/zoetigheid/Durum.jpg'
import FistikliBaklava from './img/zoetigheid/FistikliBaklava.jpg'
import FistikliSarma from './img/zoetigheid/FistikliSarma.jpg'
import HavucDilimi from './img/zoetigheid/HavucDilimi.jpg'
import KokosVezir from './img/zoetigheid/KokosVezir.jpg'
import SarmaKadayif from './img/zoetigheid/SarmaKadayif.jpg'
import Sekerpare from './img/zoetigheid/Sekerpare.jpg'
import Sobiyet from './img/zoetigheid/Sobiyet.jpg'
import Sultan from './img/zoetigheid/Sultan.jpg'
import Ucgen from './img/zoetigheid/Ucgen.jpg'

import L1 from './img/lokum/L1.jpg'
import L2 from './img/lokum/L2.jpg'
import L3 from './img/lokum/L3.jpg'
import L4 from './img/lokum/L4.jpg'
import L5 from './img/lokum/L5.jpg'
import L6 from './img/lokum/L6.jpg'
import L7 from './img/lokum/L7.jpg'
import L8 from './img/lokum/L8.jpg'
import L9 from './img/lokum/L9.jpg'
import L10 from './img/lokum/L10.jpg'
import L11 from './img/lokum/L11.jpg'

import K1 from './img/koekjes/K1.jpg'
import K2 from './img/koekjes/K2.jpg'
import K3 from './img/koekjes/K3.jpg'
import K4 from './img/koekjes/K4.jpg'
import K5 from './img/koekjes/K5.jpg'
import K6 from './img/koekjes/K6.jpg'
import K7 from './img/koekjes/K7.jpg'
import K8 from './img/koekjes/K8.jpg'
import K9 from './img/koekjes/K9.jpg'
import K10 from './img/koekjes/K10.jpg'
import K11 from './img/koekjes/K11.jpg'
import K12 from './img/koekjes/K12.jpg'

import Simit from './img/broodjes/Simit.jpg'
import Borek from './img/broodjes/Borek.jpg'
import Pogca from './img/broodjes/Pogca.jpg'
import Croissant from './img/broodjes/Croissant.jpg'

import D1 from './img/donuts/D1.jpg'
import D2 from './img/donuts/D2.jpg'
import D3 from './img/donuts/D3.jpg'
import D4 from './img/donuts/D4.jpg'
import D5 from './img/donuts/D5.jpg'
import D6 from './img/donuts/D6.jpg'
import D7 from './img/donuts/D7.jpg'

import W1 from './img/wafels/W1.jpg'
import W2 from './img/wafels/W2.jpg'
import W3 from './img/wafels/W3.jpg'
import W4 from './img/wafels/W4.jpg'

import FeestTaart1 from './img/feesttaart/FeestTaart1.jpg'
import FeestTaart2 from './img/feesttaart/FeestTaart2.jpg'
import FeestTaart3 from './img/feesttaart/FeestTaart3.jpg'
import FeestTaart4 from './img/feesttaart/FeestTaart4.jpg'
import FeestTaart5 from './img/feesttaart/FeestTaart5.jpg'
import FeestTaart6 from './img/feesttaart/FeestTaart6.jpg'
import FeestTaart7 from './img/feesttaart/FeestTaart7.jpg'
import FeestTaart8 from './img/feesttaart/FeestTaart8.jpg'
import FeestTaart9 from './img/feesttaart/FeestTaart9.jpg'
import FeestTaart10 from './img/feesttaart/FeestTaart10.jpg'
import FeestTaart11 from './img/feesttaart/FeestTaart11.jpg'
import FeestTaart12 from './img/feesttaart/FeestTaart12.jpg'
import FeestTaart13 from './img/feesttaart/FeestTaart13.jpg'
import FeestTaart14 from './img/feesttaart/FeestTaart14.jpg'
import FeestTaart15 from './img/feesttaart/FeestTaart15.jpg'
import FeestTaart16 from './img/feesttaart/FeestTaart16.jpg'
import FeestTaart17 from './img/feesttaart/FeestTaart17.jpg'
import FeestTaart18 from './img/feesttaart/FeestTaart18.jpg'
import FeestTaart19 from './img/feesttaart/FeestTaart19.jpg'
import FeestTaart20 from './img/feesttaart/FeestTaart20.jpg'
import FeestTaart26 from './img/feesttaart/FeestTaart26.jpg'
import FeestTaart27 from './img/feesttaart/FeestTaart27.jpg'
import FeestTaart28 from './img/feesttaart/FeestTaart28.jpg'
import FeestTaart29 from './img/feesttaart/FeestTaart29.jpg'
import FeestTaart31 from './img/feesttaart/FeestTaart31.jpg'
import FeestTaart32 from './img/feesttaart/FeestTaart32.jpg'
import FeestTaart33 from './img/feesttaart/FeestTaart33.jpg'
import FeestTaart34 from './img/feesttaart/FeestTaart34.jpg'
import FeestTaart35 from './img/feesttaart/FeestTaart35.jpg'
import FeestTaart36 from './img/feesttaart/FeestTaart36.jpg'
import FeestTaart37 from './img/feesttaart/FeestTaart37.jpg'
import FeestTaart38 from './img/feesttaart/FeestTaart38.jpg'
import FeestTaart39 from './img/feesttaart/FeestTaart39.jpg'
import FeestTaart40 from './img/feesttaart/FeestTaart40.jpg'
import FeestTaart41 from './img/feesttaart/FeestTaart41.jpg'
import FeestTaart42 from './img/feesttaart/FeestTaart42.jpg'
import FeestTaart43 from './img/feesttaart/FeestTaart43.jpg'
import FeestTaart44 from './img/feesttaart/FeestTaart44.jpg'


export const categories = [
    {
      id: 1,
      img: Taarten,
      title: "Taarten en gebak",
      link: "/onsAssortiment/taarten",
    },
    {
      id: 2,
      img: Bonbons,
      title: "Bonbons",
      link: "/onsAssortiment/bonbons",
    },
    {
      id: 3,
      img: TurksFruit,
      title: "Turks fruit",
      link: "/onsAssortiment/turksfruit",
    },
    {
      id: 4,
      img: Ijs,
      title: "Ijs en ijstaarten",
      link: "/onsAssortiment/ijs",
    },
    {
      id: 5,
      img: Baklava,
      title: "Zoetigheid",
      link: "/onsAssortiment/zoetigheid",
    },
    {
      id: 6,
      img: Koffie,
      title: "Warme en koude dranken",
      link: "/onsAssortiment/dranken",
    },
    {
      id: 7,
      img: Wafel,
      title: "Wafels en crêpes",
      link: "/onsAssortiment/wafels",
    },
    {
      id: 8,
      img: Broodje,
      title: "Börek en broodjes",
      link: "/onsAssortiment/broodjes",
    },
    {
      id: 9,
      img: Macaron,
      title: "Macarons en koekjes",
      link: "/onsAssortiment/koekjes",
    },
    {
      id: 10,
      img: Donuts,
      title: "Donuts",
      link: "/onsAssortiment/donuts",
    },
    {
      id: 11,
      img: Feesttaart,
      title: "Feesttaarten",
      link: "/onsAssortiment/feesttaarten",
    },
  ];

  export const categoriesFood = [
    {
      id: 1,
      img: Ontbijt,
      title: "Ontbijt",
      link: "/onsAssortiment/ontbijt",
    },
    {
      id: 2,
      img: Lunch,
      title: "Lunch",
      link: "/onsAssortiment/lunch",
    },
  ];

  export const ontbijtProductItems = [
    {
      id: 1,
      img: Kinder,
      title: "Kinder",
      desc: "Brood: 1x sneetje brood | simit | Zoetwaren: jam | nutella | hagelslag | Warm:1x gekookte scharleitje | Dranken: chocolademelk",
    },
    {
      id: 2,
      img: Standaart,
      title: "Standaard",
      desc: "Brood: 1x roomboter croissant | 1x stuk brood | Zoetwaren: boter/jam Beleg: kaas | feta kaas | groenten | Warm:1x gekookte scharleitje | Dranken: koffie, thee en limonade | Nagerecht: turks fruit | koekjes",
    },
    {
      id: 3,
      img: Luxe,
      title: "Luxe",
      desc: "Brood: 1x roomboter croissant | 1x stuk brood | 1x simit Zoetwaren: boter/jam | Beleg: kaas | feta kaas | salami | groenten | Warm:1x gekookte scharleitje | Dranken: koffie, thee en limonade | Nagerecht: turks fruit | koekjes",
    },
    {
      id: 4,
      img: SuperLuxe,
      title: "Superluxe",
      desc: "Brood: 1x roomboter croissant | 1x stuk brood | 1x simit | Zoetwaren: boter/jam | Beleg: kaas | feta kaas | salami | groenten | Warm:1x gekookte scharleitje | 1x scrambled ei met worst | Dranken: koffie, thee en limonade | Nagerecht: turks fruit | koekjes",
    },
  ];

  export const ijsProductItems = [
    {
      id: 1,
      img: Aardbei,
      title: "Aardbei",
    },
    {
      id: 2,
      img: Mokka,
      title: "Mokka",
    },
    {
      id: 3,
      img: Vanille,
      title: "Vanille",
    },
    {
      id: 4,
      img: Watermeloen,
      title: "Watermeloen",
    },
    {
      id: 5,
      img: Banaan,
      title: "Banaan",
    },
    {
      id: 6,
      img: Bloedsinaasappel,
      title: "Bloedsinaasappel",
    },
    {
      id: 7,
      img: Blueberry,
      title: "Blueberry",
    },
    {
      id: 8,
      img: Bubblegum,
      title: "Bubbelgum",
    },
    {
      id: 9,
      img: Caramel,
      title: "Caramel",
    },
    {
      id: 10,
      img: Chocolade,
      title: "Chocolade",
    },
    {
      id: 11,
      img: ChocoladeWit,
      title: "Witte chocolade",
    },
    {
      id: 12,
      img: Citroen,
      title: "Citroen",
    },
    {
      id: 13,
      img: Cocos,
      title: "Cocos",
    },
    {
      id: 14,
      img: Cookies,
      title: "Cookies",
    },
    {
      id: 15,
      img: Flamingo,
      title: "Flamingo fristi",
    },
    {
      id: 16,
      img: Framboos,
      title: "Framboos",
    },
    {
      id: 17,
      img: Hazelnoot,
      title: "Hazelnoot",
    },
    {
      id: 18,
      img: KinderBueno,
      title: "Kinder bueno",
    },
    {
      id: 19,
      img: Kitkat,
      title: "Kitkat",
    },
    {
      id: 20,
      img: Mango,
      title: "Mango",
    },
    {
      id: 21,
      img: Meloen,
      title: "Meloen",
    },
    {
      id: 22,
      img: Suikerspin,
      title: "Suikerspin",
    },
    {
      id: 23,
      img: Oreo,
      title: "Oreo",
    },
    {
      id: 24,
      img: Pistache,
      title: "Pistache",
    },
    {
      id: 25,
      img: Smurf,
      title: "Smurf",
    },
    {
      id: 26,
      img: Snickers,
      title: "Snicker",
    },
    {
      id: 27,
      img: Stroopwafel,
      title: "Stroopwafel",
    },
    {
      id: 28,
      img: Stracciatella,
      title: "Stracciatella",
    },
    {
      id: 29,
      img: YoghurtKers,
      title: "Yoghurt Kers",
    },
    {
      id: 30,
      img: HazelnootPraline,
      title: "Hazelnoot Praline",
    },
    {
      id: 31,
      img: OreoCookies,
      title: "Oreo Cookies",
    },
    {
      id: 32,
      img: FramboosAardbei,
      title: "Framboos Aardbei",
    },
    {
      id: 33,
      img: CherryChoco,
      title: "Cherry Choco",
    },
  ];

  export const taartenProductItems = [
    {
      id: 1,
      img: TripleRedVelvet,
      title: "Triple red velvet",
    },
    {
      id: 2,
      img: Rainbow,
      title: "Rainbow",
    },
    {
      id: 3,
      img: CaramelCrush,
      title: "Caramel crush",
    },
    {
      id: 4,
      img: Cheesecake,
      title: "Cheesecake",
    },
    {
      id: 5,
      img: CheesecakeFrambozen,
      title: "Cheesecake frambozen",
    },
    {
      id: 6,
      img: CarrotCake,
      title: "Carrot cake",
    },
    {
      id: 7,
      img: LemonPie,
      title: "Lemon pie",
    },
    {
      id: 8,
      img: NakedSinaasappel,
      title: "Naked sinaasappel",
    },
    {
      id: 9,
      img: OreoTaart,
      title: "Oreo",
    },
    {
      id: 10,
      img: NakedFramboos,
      title: "Naked framboos",
    },
    {
      id: 11,
      img: MokkaNougat,
      title: "Mokka nougat",
    },
    {
      id: 12,
      img: StracciatellaTaart,
      title: "Stracciatella",
    },
    {
      id: 13,
      img: Brownie,
      title: "Brownie",
    },
    {
      id: 14,
      img: RedVelvet,
      title: "Red velvet",
    },
    {
      id: 15,
      img: StroopwafelTaart,
      title: "Stroopwafel",
    },
    {
      id: 16,
      img: AardbeienBavarois,
      title: "Aardbeien bavarois",
    },
    {
      id: 17,
      img: HazelnootBavarrois,
      title: "Hazelnoot bavarois",
    },
    {
      id: 18,
      img: SinaasappelBavarrois,
      title: "Sinaasappel bavarois",
    },
    {
      id: 19,
      img: TropicalBavarois,
      title: "Tropical bavarois",
    },
    {
      id: 20,
      img: ZeeuwseBabbelaar,
      title: "Zeeuwse babbelaar",
    },
    {
      id: 21,
      img: CitroenBavarois,
      title: "Citroen bavarois",
    },
    {
      id: 22,
      img: Amandel,
      title: "Amandel",
    },
    {
      id: 23,
      img: AppeltaartGrootmoeders,
      title: "Appeltaart grootmoeders",
    },
    {
      id: 24,
      img: AppeltaartBetuwse,
      title: "Appeltaart betuwse",
    },
    {
      id: 25,
      img: Hazelinos,
      title: "Hazilinos",
    },
    {
      id: 26,
      img: AardbeienKneiters,
      title: "Aardbeien kneiters",
    },
    {
      id: 27,
      img: Moorkoppen,
      title: "Moorkoppen",
    },
    {
      id: 28,
      img: BanaanSoezen,
      title: "Banaan soezen",
    },
    {
      id: 29,
      img: CremeAuBeurreGebak,
      title: "Creme au beurre",
    },
    {
      id: 30,
      img: CappucinoCreme,
      title: "Cappucino creme",
    },
    {
      id: 31,
      img: MokkaCreme,
      title: "Mokka creme",
    },
    {
      id: 32,
      img: ChocoladeCremeGebak,
      title: "Chocolade creme",
    },
    {
      id: 33,
      img: VeganCarrotTaart,
      title: "Vegan carrot cake",
    },
    {
      id: 34,
      img: VeganAppelkruimeltaart,
      title: "Vegan appelkruimel",
    },
    {
      id: 35,
      img: ChocoladeTaart,
      title: "Chocolade taart",
    },
    {
      id: 36,
      img: CitroenMerengue,
      title: "Citroen merengue",
    },
    {
      id: 37,
      img: CheesecakeTaart,
      title: "Cheesecake taart",
    },
    {
      id: 38,
      img: HazelnootTaartHotkamp,
      title: "Hazelnoot",
    },
    {
      id: 39,
      img: BananaBreak,
      title: "Banana break",
    },
    {
      id: 40,
      img: BrownieBastard,
      title: "Brownie bastard",
    },
    {
      id: 41,
      img: LemonLicious,
      title: "Lemon licious",
    },
    {
      id: 42,
      img: CarrotCaramba,
      title: "Carrot caramba",
    },
  ];

  export const koekjesProductItems = [
    {
      id: 1,
      img: AardbeiMacaron,
      title: "Aardbei",
    },
    {
      id: 2,
      img: HazelnootMacaron,
      title: "Hazelnoot",
    },
    {
      id: 4,
      img: CitroenMacaron,
      title: "Citroen",
    },
    {
      id: 5,
      img: caramelMacaron,
      title: "Caramel",
    },
    {
      id: 6,
      img: CassisMacaron,
      title: "Casis",
    },
    {
      id: 7,
      img: MadagaskarMacaron,
      title: "Madagaskar",
    },
    {
      id: 8,
      img: PassievruchtMacaron,
      title: "Passievrucht",
    },
    {
      id: 9,
      img: PistacheMacaron,
      title: "Pistache",
    },
    {
      id: 10,
      img: ChocoladeMacaron,
      title: "Chocolade",
    },
    {
      id: 11,
      img: ChocoladeRoomboterkoekjes,
      title: "Chocolade",
    },
    {
      id: 12,
      img: HazelnootRoomboterkoekjes,
      title: "Hazelnoot",
    },
    {
      id: 13,
      img: CaramelAnanasRoomboterkoekjes,
      title: "Caramel ananas",
    },
    {
      id: 14,
      img: WitteChocoladeRoomboterkoekjes,
      title: "Witte chocolade",
    },
    {
      id: 15,
      img: MangoCaramelRoomboterkoekjes,
      title: "Mango caramel",
    },
    {
      id: 16,
      img: BlueberryKoekjes,
      title: "Blueberry",
    },
    {
      id: 17,
      img: Stroopwafeltjes,
      title: "Stroopwafeltjes",
    },
    {
      id: 18,
      img: K1,
      title: "Banaan",
    },
    {
      id: 19,
      img: K2,
      title: "Cacao",
    },
    {
      id: 20,
      img: K3,
      title: "Framboos",
    },
    {
      id: 21,
      img: K4,
      title: "Naturel Chocolade",
    },
    {
      id: 22,
      img: K5,
      title: "Pistache",
    },
    {
      id: 23,
      img: K6,
      title: "Framboos Chocolade",
    },
    {
      id: 24,
      img: K7,
      title: "Chocolade kokos",
    },
    {
      id: 25,
      img: K8,
      title: "Sesam",
    },
    {
      id: 26,
      img: K9,
      title: "Aardbij",
    },
    {
      id: 27,
      img: K10,
      title: "Pistache Naturel",
    },
    {
      id: 28,
      img: K11,
      title: "Manderijn",
    },
    {
      id: 29,
      img: K12,
      title: "Vanille",
    },
  ];

  export const bonbonsProductItems = [
    {
      id: 1,
      img: BanaanRoyaal,
      title: "Banaan Royaal",
    },
    {
      id: 2,
      img: Krokanta,
      title: "Krokanta",
    },
    {
      id: 3,
      img: Zacamar,
      title: "Zacamar",
    },
    {
      id: 4,
      img: Barry,
      title: "Barry",
    },
    {
      id: 5,
      img: Cafe,
      title: "Cafe",
    },
    {
      id: 6,
      img: Amarena,
      title: "Amarena",
    },
    {
      id: 7,
      img: Bouches,
      title: "Bouches",
    },
    {
      id: 8,
      img: DuoNoix,
      title: "Duo Noix",
    },
    {
      id: 9,
      img: KennsBrownie,
      title: "Kenns Brownie",
    },
    {
      id: 10,
      img: PindaCrunch,
      title: "Pinda Crunch",
    },
    {
      id: 11,
      img: Abrinout,
      title: "Abrinout",
    },
    {
      id: 12,
      img: TonkaKoffie,
      title: "Tonka Koffie",
    },
    {
      id: 13,
      img: GreenTea,
      title: "Green Tea",
    },
    {
      id: 14,
      img: Nutella,
      title: "Nutella",
    },
    {
      id: 15,
      img: LaBorde,
      title: "La Borde",
    },
    {
      id: 16,
      img: Picasso,
      title: "Picasso",
    },
    {
      id: 17,
      img: OpenTrioGanache,
      title: "Open Trio Ganache",
    },
    {
      id: 18,
      img: Sandoorn,
      title: "Sandoorn",
    },
    {
      id: 19,
      img: FleurDeSel,
      title: "Fleur De Sel",
    },
    {
      id: 20,
      img: Florentine,
      title: "Florentine",
    },
    {
      id: 21,
      img: MarulaMacchiato,
      title: "Marula Macchiato",
    },
    {
      id: 22,
      img: PistacheBonbon,
      title: "Pistache",
    },
    {
      id: 23,
      img: Mandarino,
      title: "Mandarino",
    },
    {
      id: 24,
      img: StroopwafelBonbon,
      title: "Stroopwafel",
    },
    {
      id: 25,
      img: Mokha,
      title: "Mokha",
    },
    {
      id: 26,
      img: AppelKaneel,
      title: "Appel Kaneel",
    },
    {
      id: 27,
      img: MangoCheesecake,
      title: "Mango Cheesecake",
    },
    {
      id: 28,
      img: PomeloRosa,
      title: "Pomelo Rosa",
    },
    {
      id: 29,
      img: CoconutDream,
      title: "Coconut Dream",
    },
    {
      id: 30,
      img: XtraPuur,
      title: "Xtra Puur",
    },
    {
      id: 31,
      img: Poire,
      title: "Poire",
    },
    {
      id: 32,
      img: AardbeiBonbon,
      title: "Aardbei",
    },
    {
      id: 33,
      img: DropCaramelZoethout,
      title: "Drop Caramel Zoethout",
    },
    {
      id: 34,
      img: FramboosMint,
      title: "Framboos Mint",
    },
    {
      id: 35,
      img: Boterkoek,
      title: "Boterkoek",
    },
    {
      id: 36,
      img: BonbonXLMelkRoomboterCaramel,
      title: "Bonbon XL Melk Roomboter Caramel",
    },
    {
      id: 37,
      img: BonbonXLPuurKrokanteHazelnootPraline,
      title: "Bonbon XL Puur Krokante Hazelnoot Praline",
    },
    {
      id: 38,
      img: BonbonXLWitMandarijnYoghurt,
      title: "Bonbon XL Wit Mandarijn Yoghurt",
    },
    {
      id: 39,
      img: Truffel,
      title: "Truffel",
    },
    {
      id: 40,
      img: Pendule,
      title: "Pendule",
    },
    {
      id: 41,
      img: Walnos,
      title: "Walnos",
    },
    {
      id: 42,
      img: Rachel,
      title: "Rachel",
    },
    {
      id: 43,
      img: Gember,
      title: "Gember",
    },
  ];

  export const zoetigheidProductItems = [
    {
      id: 1,
      img: Z1,
      title: "Amandel",
    },
    {
      id: 2,
      img: Z2,
      title: "Hazelnoot",
    },
    {
      id: 3,
      img: Z3,
      title: "Honing",
    },
    {
      id: 4,
      img: Z4,
      title: "Amandel sesam",
    },
    {
      id: 5,
      img: Z5,
      title: "Walnoot",
    },
    {
      id: 7,
      img: Z7,
      title: "Pistache",
    },
    {
      id: 8,
      img: Z8,
      title: "Aardbij",
    },
    {
      id: 9,
      img: Z9,
      title: "Melk chocolade",
    },
    {
      id: 10,
      img: Z10,
      title: "Caramel",
    },
    {
      id: 11,
      img: Z11,
      title: "Dadel walnoot",
    },
    {
      id: 12,
      img: Z12,
      title: "Naturel",
    },
    {
      id: 13,
      img: Z13,
      title: "nootjes",
    },
    {
      id: 14,
      img: Z14,
      title: "Kokos cacao",
    },
    {
      id: 15,
      img: Z15,
      title: "Baklava",
    },
    {
      id: 16,
      img: Z16,
      title: "Naturel rond",
    },
    {
      id: 17,
      img: BasmaKadayif,
      title: "#Basma kadayif",
    },
    {
      id: 18,
      img: BurmaKadayif,
      title: "Burma kadayif",
    },
    {
      id: 19,
      img: Vezir,
      title: "Vezir",
    },
    {
      id: 20,
      img: CevizliBaklava,
      title: "#Cevizli baklava",
    },
    {
      id: 21,
      img: CevizliDolma,
      title: "Cevizli dolma",
    },
    {
      id: 22,
      img: CevizliDurum,
      title: "Cevizli durum",
    },
    {
      id: 23,
      img: CikolataliBaklava,
      title: "Cikolatali baklava",
    },
    {
      id: 24,
      img: Dilber,
      title: "Dilber",
    },
    {
      id: 25,
      img: Durum,
      title: "Durum",
    },
    {
      id: 26,
      img: FistikliBaklava,
      title: "Fistikli baklava",
    },
    {
      id: 27,
      img: FistikliSarma,
      title: "Fistikli sarma",
    },
    {
      id: 28,
      img: HavucDilimi,
      title: "Havuc dilimi",
    },
    {
      id: 29,
      img: KokosVezir,
      title: "Kokos vezir",
    },
    {
      id: 30,
      img: SarmaKadayif,
      title: "Sarma kadayif",
    },
    {
      id: 31,
      img: Sekerpare,
      title: "Sekerpare",
    },
    {
      id: 32,
      img: Ucgen,
      title: "Ucgen",
    },
    {
      id: 33,
      img: Sultan,
      title: "Sultan",
    },
    {
      id: 34,
      img: Sobiyet,
      title: "Sobiyet",
    },
  ];

  export const turksFruitProductItems = [
    {
      id: 1,
      img: L1,
      title: "Hazelnoot",
    },
    {
      id: 2,
      img: L2,
      title: "Psitache",
    },

    {
      id: 3,
      img: L3,
      title: "Cacao",
    },
    {
      id: 4,
      img: L4,
      title: "Vinger caramel",
    },
    {
      id: 5,
      img: L5,
      title: "Cocos",
    },
    {
      id: 6,
      img: L6,
      title: "Naturel",
    },
    {
      id: 7,
      img: L7,
      title: "Vinger Pistache",
    },
    {
      id: 8,
      img: L8,
      title: "Room",
    },
    {
      id: 9,
      img: L9,
      title: "Pistache cacao",
    },
    {
      id: 10,
      img: L10,
      title: "Chocolade",
    },
    {
      id: 11,
      img: L11,
      title: "Granaatappel walnoot",
    },
  ];

  export const broodjesProductItems = [
    {
      id: 1,
      img: Croissant,
      title: "Roomboter croissant",
    },
    {
      id: 2,
      img: Simit,
      title: "Simit",
    },
    {
      id: 3,
      img: Pogca,
      title: "Pogaça",
    },
    {
      id: 4,
      img: Borek,
      title: "Börek",
    },
  ];

  export const donutsProductItems = [
    {
      id: 1,
      img: D1,
      title: "Framboos",
    },
    {
      id: 2,
      img: D2,
      title: "Pistache",
    },
    {
      id: 3,
      img: D3,
      title: "Raffaello",
    },
    {
      id: 4,
      img: D4,
      title: "Oreo",
    },
    {
      id: 5,
      img: D5,
      title: "Chocolade en nootjes",
    },
    {
      id: 6,
      img: D6,
      title: "Chocolade Puur",
    },
    {
      id: 7,
      img: D7,
      title: "Chocolade",
    },
  ];

  export const wafelsProductItems = [
    {
      id: 1,
      img: W1,
      title: "Crêpes aardbij banaan",
    },
    {
      id: 2,
      img: W2,
      title: "Wafel framboos",
    },
    {
      id: 3,
      img: W3,
      title: "Crêpes banaan",
    },
    {
      id: 4,
      img: W4,
      title: "Crêpes",
    },
  ];

  export const feesttaartenProductItems = [
    {
      id: 1,
      img: FeestTaart1,
      title: "#1",
    },
    {
      id: 2,
      img: FeestTaart2,
      title: "#2",
    },
    {
      id: 3,
      img: FeestTaart3,
      title: "#3",
    },
    {
      id: 4,
      img: FeestTaart4,
      title: "#4",
    },
    {
      id: 5,
      img: FeestTaart5,
      title: "#5",
    },
    {
      id: 6,
      img: FeestTaart6,
      title: "#6",
    },
    {
      id: 7,
      img: FeestTaart7,
      title: "#7",
    },
    {
      id: 8,
      img: FeestTaart8,
      title: "#8",
    },
    {
      id: 9,
      img: FeestTaart9,
      title: "#9",
    },
    {
      id: 10,
      img: FeestTaart10,
      title: "#10",
    },
    {
      id: 11,
      img: FeestTaart11,
      title: "#11",
    },
    {
      id: 12,
      img: FeestTaart12,
      title: "#12",
    },
    {
      id: 13,
      img: FeestTaart13,
      title: "#13",
    },
    {
      id: 14,
      img: FeestTaart14,
      title: "#14",
    },
    {
      id: 15,
      img: FeestTaart15,
      title: "#15",
    },
    {
      id: 16,
      img: FeestTaart16,
      title: "#16",
    },
    {
      id: 17,
      img: FeestTaart17,
      title: "#17",
    },
    {
      id: 18,
      img: FeestTaart18,
      title: "#18",
    },
    {
      id: 19,
      img: FeestTaart19,
      title: "#19",
    },
    {
      id: 20,
      img: FeestTaart20,
      title: "#20",
    },
    {
      id: 21,
      img: FeestTaart26,
      title: "#21",
    },
    {
      id: 22,
      img: FeestTaart27,
      title: "#22",
    },
    {
      id: 23,
      img: FeestTaart28,
      title: "#23",
    },
    {
      id: 24,
      img: FeestTaart29,
      title: "#24",
    },
    {
      id: 25,
      img: FeestTaart31,
      title: "#25",
    },
    {
      id: 26,
      img: FeestTaart32,
      title: "#26",
    },
    {
      id: 27,
      img: FeestTaart33,
      title: "#27",
    },
    {
      id: 28,
      img: FeestTaart34,
      title: "#28",
    },
    {
      id: 29,
      img: FeestTaart35,
      title: "#29",
    },
    {
      id: 30,
      img: FeestTaart36,
      title: "#30",
    },
    {
      id: 31,
      img: FeestTaart37,
      title: "#31",
    },
    {
      id: 32,
      img: FeestTaart38,
      title: "#32",
    },
    {
      id: 33,
      img: FeestTaart39,
      title: "#33",
    },
    {
      id: 34,
      img: FeestTaart40,
      title: "#34",
    },
    {
      id: 35,
      img: FeestTaart41,
      title: "#35",
    },
    {
      id: 36,
      img: FeestTaart42,
      title: "#36",
    },
    {
      id: 37,
      img: FeestTaart43,
      title: "#37",
    },
    {
      id: 38,
      img: FeestTaart44,
      title: "#38",
    },
  ];