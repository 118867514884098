import React from 'react';
import { Card, Col } from 'react-bootstrap';

const ProductFood = ({ item }) => {
    return (
        <Col>
            <Card className="cards-row">
                <Card.Img variant="top" src={item.img} />
                <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>
                    {item.desc}
                </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ProductFood