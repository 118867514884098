import React, {useState} from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import './scrollButton.css'

const ScrollButton = () => {
    const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 600){
        setVisible(true)
        } 
        else if (scrolled <= 600){
        setVisible(false)
        }
    };
    
    const scrollToTop = () =>{
        window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        });
    };
    
    window.addEventListener('scroll', toggleVisible);
    return (
            <ArrowCircleUpIcon className='scroll-button' onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} />
    )
}

export default ScrollButton
