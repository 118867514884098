import React from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import OverOns from '../components/overOns/OverOns'
import Footer from '../components/footer/Footer'

const OverOnsPage = () => {
    return (
        <>
        <Navbar/>
        <OverOns/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default OverOnsPage
