import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './navbar.css';

const navbar = () => {
    return (
        <Navbar className="main-nav" expand="lg">
        <Container>
            <Navbar.Brand href="#home">
                <NavLink className='link' to="/">Lafiore</NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link>
                    <NavLink activeClassName="active" className='link' to="/overOns">Over Ons</NavLink>
                </Nav.Link>
                <NavDropdown title="Ons Assortiment" id="basic-nav-dropdown">
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/taarten">Taarten &amp; Gebak</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/bonbons">Bonbons</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/turksfruit">Turks fruit</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/ijs">Ijs &amp; Ijstaarten</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/zoetigheid">Zoetigheid</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/dranken">Warme &amp; koude dranken</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/wafels">Wafels &amp; crêpes</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/broodjes">Börek &amp; broodjes</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/koekjes">Macarons &amp; koekjes</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/donuts">Donuts</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/feesttaarten">Feesttaarten</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/ontbijt">Ontbijt</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <NavLink className='link dropdown-item' to="/onsAssortiment/lunch">Lunch</NavLink>
                </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link>
                    <NavLink className='link' to="/fotogalerij">Foto Galerij</NavLink>
                </Nav.Link>
                <Nav.Link>
                    <NavLink className='link' to="/contact">Contact</NavLink>
                </Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}

export default navbar
