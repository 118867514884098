import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Product from '../productItem/Product'
import './products.css'

const Products = ({productItems}) => {
    return (
        <>
        <Container className='container-products'>
        <Row xs={1} md={3} className="g-4">
            {productItems.map((item) => (
                <Product item={item} key={item.id} />
            ))}
        </Row>
        </Container>
        </>
    )
}

export default Products
