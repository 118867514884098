import React, { useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './contactForm.css';
import emailjs from 'emailjs-com';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const ContactForm = () => {
    const formRef = useRef();
    const [done, setDone] = useState(false)
    
    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_8ycm5pn', 'template_8ecung4', formRef.current, 'user_qS7G7Da9C93aK3aHzDv9d')
        .then((result) => {
            console.log(result.text);
            setDone(true)
            document.getElementById("name-email").value = "";
            document.getElementById("message").value = "";
        }, (error) => {
            console.log(error.text);
        });
      };

    return (
        <div className="contact-form">
        <Container className="container-contact-form">
        <Row xs={1} md={3}>
            <Col>
            <h4 className="adres-title">CONTACT</h4>
                <Form role="form" ref={formRef} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Uw email adres</Form.Label>
                    <Form.Control id='name-email' type="email" name="from_name" required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Uw bericht</Form.Label>
                    <Form.Control id='message' as="textarea" rows={3} name="message" required />
                </Form.Group>
                <Button className="form-button" variant="primary" type="submit">
                    Verzenden
                </Button>
                {done && "Uw bericht is ontvangen..."}
                </Form>
            </Col>
            <Col className="adres">
            <div>
            <h4 className="adres-title">Lafiore</h4>
                <p><LocationOnIcon fontSize="small" className='icon-items'/>Marsmanplein 23</p>
                <p>2025 DV Haarlem</p>
                <p><a className='link' href="mailto:info@la-fiore.nl"><EmailIcon fontSize="small" className='icon-items'/>info@la-fiore.nl</a></p>
                <p><a className='link' href="tel:0613151414"><PhoneIcon fontSize="small" className='icon-items'/>06 13151414</a></p>
            </div>
            </Col>
            <Col className="adres tijden">
            <div>
            <h4 className="adres-title">Openingstijden</h4>
                <p>Maandag t/m zondag 12:00 - 22:00</p>
                <h4 className="adres-title">Socialmedia</h4>
                <p><a href='https://www.facebook.com/La-fiore-233716525233017' rel="noreferrer" target="_blank"><FacebookOutlinedIcon className='icon-items'/></a></p>
            </div>
            </Col>
        </Row>
        </Container>
        </div>
    )
}

export default ContactForm
