import React, { useEffect } from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import './productPage.css'
import Products from '../components/products/Products'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { ijsProductItems } from "../data";
import { Link } from 'react-router-dom'


const IjsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Ijs en ijstaarten</Breadcrumb.Item>
            </Breadcrumb>             
        </Container>
        <Container>
        <h3 className="sub-headers">Ijs en ijstaarten</h3>
        <p className="over-ons-p"><Badge bg="info">1 bol &euro;1,30</Badge><Badge bg="info">2 bollen &euro;2.60</Badge><Badge bg="info">3 bollen &euro;3,80</Badge><Badge bg="info">4 bollen &euro;4,90</Badge><Badge bg="info">5 bollen &euro;5,90</Badge><Badge bg="info">+ 1 bol &euro;1</Badge></p>
        <p className="over-ons-p"><Badge bg="info">500 gram &euro;9,00</Badge><Badge bg="info">1kg &euro;16,00</Badge><Badge bg="info">slagroom  &euro;0,50</Badge><Badge bg="info">spikkels &euro;0,50</Badge></p>
        </Container>
        <ScrollButton/>
        <Products productItems={ijsProductItems}/>

        <ContactForm/>
        <Footer/>
        </>
    )
}

export default IjsPage
