import React, { useEffect } from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import './productPage.css'
import Products from '../components/products/Products'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { taartenProductItems } from "../data";
import { Link } from 'react-router-dom'


const TaartenPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Taarten en gebak</Breadcrumb.Item>
            </Breadcrumb>            
        </Container>
        <Container>
            <h3 className="sub-headers">Taarten en gebak</h3>
            <p className="over-ons-p"><Badge bg="info">Assortiment- taarten en gebak- taarten gesneden: &euro;3,50</Badge><Badge bg="info">appeltaarten: &euro;3,50</Badge><Badge bg="info">klein gebak: &euro;3,50</Badge><Badge bg="info">cakes verpakt: &euro;2,25</Badge></p>
        </Container>
        <ScrollButton/>
        <Products productItems={taartenProductItems}/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default TaartenPage
