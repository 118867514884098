import React, { useEffect } from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Breadcrumb, Container, ListGroup, Row } from 'react-bootstrap'
import './productPage.css'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { Link } from 'react-router-dom'


const DrankenPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Warme en koude dranken</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
        <Container>
            <h3 className="sub-headers">Warme en koude dranken</h3>
            <Row xs={1} md={2} className="g-4">
                <ListGroup as="ol">
                    <ListGroup.Item action variant="light" as="li">Koffie</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">espresso</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">Dubbele espresso</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">Cappuccino</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">koffie verkeerd</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">Latte macchiato</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">Diverse smaken thee</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">Warme chocolademelk</ListGroup.Item>
                    <ListGroup.Item action variant="light" as="li">Frisdrank</ListGroup.Item>
                </ListGroup>
            </Row>
        </Container>
        <ScrollButton/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default DrankenPage
