import React, { useEffect } from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import './productPage.css'
import Products from '../components/products/Products'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { broodjesProductItems } from "../data";
import { Link } from 'react-router-dom'


const BroodjesPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Börek en broodjes</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
        <Container>
            <h3 className="sub-headers">Börek en broodjes</h3>
            <p className="over-ons-p"><Badge bg="info">Börek: gehakt, witte kaas, spinazie, aardappel, döner en su burek &euro; 2,75</Badge><Badge bg="info">Simit: &euro;1,00</Badge><Badge bg="info">Croissant: &euro;1,00</Badge></p>
        </Container>
        <ScrollButton/>
        <Products productItems={broodjesProductItems}/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default BroodjesPage
