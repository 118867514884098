import React, { useEffect } from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import './productPage.css'
import Products from '../components/products/Products'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { koekjesProductItems } from "../data";
import { Link } from 'react-router-dom'


const KoekjesPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Macarons en koekjes</Breadcrumb.Item>
            </Breadcrumb>             
        </Container>
        <Container>
        <h3 className="sub-headers">Macarons en koekjes</h3>
        <p className="over-ons-p"><Badge bg="info">Koekjes: 100g &euro;2</Badge><Badge bg="info">250g &euro;5</Badge><Badge bg="info">500g &euro;10</Badge><Badge bg="info">1kilo &euro;20</Badge><Badge bg="info">macaron: &euro;2</Badge></p>
        </Container>
        <ScrollButton/>
        <Products productItems={koekjesProductItems}/>

        <ContactForm/>
        <Footer/>
        </>
    )
}

export default KoekjesPage
