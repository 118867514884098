import React, { useEffect } from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import './productPage.css'
import Products from '../components/products/Products'
import ScrollButton from '../components/scrollButton/ScrollButton'
import { bonbonsProductItems } from "../data";
import { Link } from 'react-router-dom'


const BonbonsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Navbar/>
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/onsAssortiment">Ons Assortiment</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Bonbons</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
        <Container>
            <h3 className="sub-headers">Bonbons</h3>
           
            <p className="over-ons-p"><Badge bg="info">100 gr: &euro;4,00</Badge><Badge bg="info">250gr: &euro;10,00</Badge><Badge bg="info">500gr: &euro;19,00</Badge><Badge bg="info">1kg: &euro;37,50</Badge></p>
            
        </Container>
        <ScrollButton/>
        <Products productItems={bonbonsProductItems}/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default BonbonsPage
