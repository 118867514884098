import React from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Foto1 from '../img/fotogalerij/fotogalerij-1.jpg'
import Foto2 from '../img/fotogalerij/fotogalerij-2.jpg'
import Foto3 from '../img/fotogalerij/fotogalerij-3.jpg'

const FotoGalerijPage = () => {
    return (
        <>
        <Navbar/>
        <Container>
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Card>
                        <Card.Img variant="top" src={Foto1} />
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src={Foto2} />
                    </Card>
                </Col>
            </Row>
            <Row xs={1} className="g-4">
                <Col>
                    <Card>
                        <Card.Img variant="top" src={Foto3} />
                    </Card>
                </Col>
            </Row>
        </Container>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default FotoGalerijPage