import React from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import './contact.css'

const ContactPage = () => {
    return (
        <>
        <Navbar/>
        <iframe className='iframe-maps' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.430977831988!2d4.645755415672187!3d52.41698827979479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5ef92fe08b75f%3A0x8a57ddc9f1e86523!2sMarsmanplein%2023%2C%202025%20DV%20Haarlem!5e0!3m2!1snl!2snl!4v1639599929292!5m2!1snl!2snl" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default ContactPage
